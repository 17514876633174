<template>
  <div class="insurePay" :class="isHeight ? 'bigheight' : ''">
    <div class="main">
      <div class="payMsg">
        <div class="left">
          <img src="../../assets/image/icon_syt@2x.png" alt="" />
          <div class="paynumber">
            <span class="text">请及时完成支付</span>
            <div>业务流水号： {{ userAcctInfo.orderNo }}</div>
          </div>
        </div>
        <div class="right">
          <div class="text1">
            <span class="red">{{
              throundsHandle(userAcctInfo.sumAmount)
            }}</span>
            <span class="text">您的保额(元)</span>
          </div>
          <div class="text2">
            <span class="red">{{
              throundsHandle(userAcctInfo.sumPremium)
            }}</span>
            <span class="text">需支付保费(元)</span>
          </div>
        </div>
      </div>
      <div class="payImg">
        <div class="text">
          <img
            width="18px"
            height="10px"
            src="../../assets/image/d@2x.png"
            alt=""
          />
          请选择支付方式：
        </div>
        <div class="main-pay">
          <div
            class="left"
            @click="open()"
            src="../../assets/image/offlinepay.png"
          >
            <img src="../../assets/image/pic_xxzf@2x.png" alt="" />
            <div class="pay">线下支付</div>
          </div>
          <div class="left" :class="userAcctInfo.sumPremium > useMoney ? 'disableds' : ''" @click="userAcctInfo.sumPremium > useMoney ? noMoney() : useOpen()">
            <img src="../../assets/image/pic_uj@2x.png" alt="" />
            <div class="pay">预缴</div>
            <div class="pays" v-if="token">
              可用<span class="use">{{ throundsHandle(useMoney) }}</span>
            </div>
            <div class="pays" v-else>
              请先登录
            </div>
          </div>
        </div>
        <!-- <div class="img">
          <div class="payType">
            <span>关联流水:</span>
            <img
              @click="gopage(`/pay?encrypted=${encrypted}`)"
              src="../../assets/image/cmbc.png"
            />
          </div>
          <div class="line" />
          <div class="payType">
            <span>随机账号:</span>
            <img
              @click="gopage(`/pay?encrypted=${encrypted}&type=noorder`)"
              src="../../assets/image/asdasd.png"
            />
          </div>
          <div class="line" />
          <div class="payType">
            <span>线下支付:</span>
            <img @click="open" src="../../assets/image/offlinepay.png" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { payList } from '@/api/pay';
import { Modal } from 'ant-design-vue';
import { getRemainingAmounts } from '@/api/premiumDeposit'
export default {
  data () {
    return {
      encrypted: null,
      userAcctInfo: {},
      token: null,
      useMoney: null,
      isHeight: false
    };
  },
  created () {
    this.encrypted = this.$route.query.encrypted;
    this.token = localStorage.getItem('token');
    this.refresh();
    if (this.token) {
      this.info()
    }
  },
  mounted () {
    if (document.documentElement.clientHeight > 768) {
      this.isHeight = true
    } else {
      this.isHeight = false
    }
  },
  methods: {
    info () {

      getRemainingAmounts(this.$route.query.encrypted).then(res => {
        this.useMoney = res.data
      })
    },
    noMoney () {
      this.$message.warning('余额不足')
    },
    throundsHandle (value) {
      if (value) {
        let a = value.toString().split('.')[0]
        let b = value.toString().split('.')[1]
        return a.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ( b ? '.' + b : '')
      } else {
        return value
      }
    },
    refresh () {
      payList(this.encrypted).then(res => {
        this.userAcctInfo = res.data.userAcctInfo
      })
    },
    gopage (url) {
      this.$router.push({ path: url })
    },
    open () {
      window.open(`${process.env.VUE_APP_FIRST_URL}/api/insurePay/offline/${this.encrypted}`, '_self')
    },
    useOpen () {
      window.open(`${process.env.VUE_APP_FIRST_URL} /api/insurePay/prepay/${this.encrypted}`, '_self')
    },
    btn () {
      Modal.info({
        title: '敬请期待！',
        okText: '取消',
        onOk () {
          console.log('ok')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.insurePay {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 72.7vh;
  overflow: hidden;
  background: url(/img/main-bg-top.a24dd2b5.png) top center no-repeat,
    url(/img/main-bg-bottom.81350c0a.png) bottom center no-repeat;
  background-size: contain;
  background-color: #f5f6fa;
  .payImg {
    margin-top: 40px;
    .text {
      font-size: 18px;
    }
    .img {
      display: flex;
      justify-content: space-between;
      padding: 0 120px;
      margin-top: 30px;
      .line {
        width: 1px;
        height: 145px;
        background-color: #ccc;
      }
      .payType {
        display: flex;
        span {
          font-size: 18px;
          margin-right: 5px;
        }
        img {
          width: 140px;
          height: 140px;
          cursor: pointer;
        }
      }
    }
  }
  .tips {
    width: 60vw;
    margin-top: 80px;
    font-size: 18px;
  }
  .main {
    width: 62vw;
    height: 66vh;
    padding: 60px;
    margin-top: 30px;
    background: #fff;
    .payMsg {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: 75px;
      border-bottom: 1px solid #eaeaea;
      .left {
        display: flex;
        img {
          width: 80px;
          height: 80px;
          margin-right: 30px;
          background: #f3f4f8;
          border-radius: 20px;
        }
        .paynumber {
          .text {
            font-size: 40px;
            font-weight: 300;
            color: #333333;
          }
        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 42%;
        .text {
          font-size: 18px;
          color: #666666;
        }
        .red {
          display: block;
          height: 54px;
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #e72923;
        }
      }
    }
    .main-pay {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      margin-top: 20px;
      .left {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        width: 45%;
        height: 23vh;
        text-align: center;
        background: #f5f6fa;
        box-shadow: 0px 0px 50px 0px rgba(51, 51, 51, 0.04);
        border-radius: 20px 20px 20px 20px;
        img {
          width: 100px;
          height: 100px;
        }
        .pay {
          width: 100%;
          font-size: 28px;
          color: #333333;
          text-align: center;
        }
        .pays {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: #666666;
        }
        .use {
          font-size: 24px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #e72923;
        }
      }
    }
  }
  .disableds {
    background: #6666662e !important;
  }
}
.bigheight {
  height: 77vh;
}
</style>
